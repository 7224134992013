





































































































































































































.actions-buttons-wrapper {
  margin-bottom: 24px;
  display: flex;

  & > div {
    width: 50%;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.diff-container {
  background-color: #ccc;
  padding: 15px;
  display: flex;

  img {
    max-width: 100%;
  }
}

.diff-container > div {
  width: 50%;
  padding: 15px;

  & > hr {
    margin-bottom: 48px;
  }
}

.diff-container > .left > h2,
.diff-container > .left > h1 {
  color: #bbb;
}

.diff-container > .right > h2,
h.diff-container > .right > h1 {
  color: #333;
}

.diff-container > .highlighted {
  border: 2px dashed blue;
}

.diff-container > .left {
  background-color: white;
  margin-right: 12px;
}

.diff-container > .right {
  margin-left: 12px;
  background-color: white;
}

ins,
.diff-ins {
  code,
  img {
    border: 2px solid #080;
  }
  img {
    opacity: 0.75;
    filter: alpha(opacity=75);
  }
  a {
    color: #080;
    text-decoration: none;
  }
}

img.diff-ins,
code.diff-ins {
  border: 2px solid #080;
}

img.diff-ins {
  opacity: 0.75;
  filter: alpha(opacity=75);
}

.diff-ins {
  color: #080;
  background: #dfd;
}

ins {
  color: #080;
  background: #dfd;
  text-decoration: none;
}

del,
.diff-del {
  code,
  img {
    border: 2px solid #b00;
  }
  img {
    opacity: 0.5;
    filter: alpha(opacity=50);
  }
  a {
    color: #b00;
    text-decoration: none;
  }
}

img.diff-del,
code.diff-del {
  border: 2px solid #b00;
}

img.diff-del {
  opacity: 0.5;
  filter: alpha(opacity=50);
}

.diff-del {
  color: #b00;
  background: #fcc;
  text-decoration: none;
}

del {
  color: #b00;
  background: #fcc;
  text-decoration: none;
}
